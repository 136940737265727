* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  scrollbar-width: none;
}
*::-webkit-scrollbar{
  width: 0px;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.app {
  flex-basis: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(182, 8, 37, 0.756) 10%,
    rgba(8, 78, 182, 0.756)
  );
}
/* Editing the inputItem field */
.addItem {
  align-self: stretch;
  padding: 5vh;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.addForm {
  height: fit-content;
  min-height: max-content;
  display: flex;
  flex-direction: column;
}
.addForm > label {
  flex-basis: 6vw;
  max-height: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.addForm > label > input {
  text-align: center;
  border-radius: 4px;
  box-shadow: none;
  padding: 1vw;
  flex-grow: 2;
  flex-shrink: 1;
}
.addForm > label > button {
  border-radius: 4px;
  box-shadow: none;
  margin-top: 2px;
  flex-grow: 1;
  padding: 0.8vw;
  flex-shrink: 2;
  transition: background, color 500ms, 1000ms ease;
}
.addForm > label > button:hover {
  background-color: greenyellow;
  color: blue;
}
.searchForm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid rgba(40, 52, 41, 0.372);
  border-radius: 5px;
}
/* Here we are editing list of tasks */
.list {
  overflow-y: scroll;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.list::-webkit-scrollbar{
  display: none;
}
.list  nav {
  padding: 1.5vmin;
  margin: 4px;
  align-self: stretch;
  font-size: large;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Here we are editing the delete button */
.list button {
  background-color: antiquewhite;
  border-radius: 10%;
  border-width: 0;
  padding: 1vw;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  transition: background, color 500ms, 500ms ease 00ms;
}
.list button:hover {
  background-color: rgba(18, 144, 22, 0.372);
  color: white;
}

@media (max-width: 1000px) {
  .addItem {
    margin-top: 5vh;
  }
}
@media (max-height: 500px) {
  .addItem{
    padding: 15vh;
  }
}
